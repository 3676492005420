import React from "react";

import { Nav, Navbar } from "react-bootstrap";
import logo from "../images/logo-linwah.svg"

const Header = class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            navBarActiveClass: "",
            hideNavbar: null
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, { passive: true })
    }

    handleScroll = () => {
        if (window.pageYOffset === 0) {
            this.setState({ hideNavbar: true })
        } else {
            this.setState({ hideNavbar: false });
        }
    };

    scrollToElement = (el) => {
        const element = document.getElementById(el);
        const nav = document.querySelector(".navbar-toggler");
        element.scrollIntoView({ behavior: 'smooth', block: 'center'});
        nav.click();
    };

    render() {
        return (
            <div>
                <div>
                    <Navbar collapseOnSelect expand="lg" bg="white"
                        className={"lw-navbar " + (this.state.hideNavbar ? 'hideNav ' : '') + (this.state.hideNavbar === false ? 'showNav' : '')}>
                        <Navbar.Brand
                            className="navbar-brand--left"
                            onClick={() => { this.scrollToElement("home") }}>
                            <img src={logo} alt="LinWah" style={{ width: "150px" }} />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="">
                                <Nav.Link onClick={() => { this.scrollToElement("menu") }}>Menu</Nav.Link>
                                <Nav.Link onClick={() => { this.scrollToElement("impressies") }}>Impressies</Nav.Link>
                            </Nav>
                            <Navbar.Brand
                                onClick={() => { this.scrollToElement("home") }}
                                className="navbar-brand--center">
                                <img src={logo} alt="LinWah" style={{ width: "200px" }} />
                            </Navbar.Brand>
                            <Nav className="ml-auto">
                                <Nav.Link href="https://bestellen.linwahalmere.nl/home">Online bestellen</Nav.Link>
                                <Nav.Link onClick={() => { this.scrollToElement("contact") }}>Contact</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </div>
        );
    }
};

export default Header;