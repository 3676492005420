import React from "react"
import 'bootstrap/dist/css/bootstrap.css';
import "../components/layout.css";
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
    <>
        <Layout>
            <SEO title="Home" keywords={[`Lin Wah`, `Almere`, `Muziekwijk`]}/>
        </Layout>
    </>
);

export default IndexPage
