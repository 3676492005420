import React from "react"
import PropTypes from "prop-types"
import {StaticQuery, graphql} from "gatsby"
import Header from "./header.js";
import Slider from "react-slick";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";

import cherry from "../images/cherry-blossoms.jpg";
import logo from "../images/logo-linwah.svg";
import img1 from "../images/img01.jpg";
import img2 from "../images/img02.jpg";
import img3 from "../images/img03.jpg";
import img4 from "../images/img04.jpg";
import img5 from "../images/img05.jpg";
import img6 from "../images/img06.jpg";
import afhaalmenu from "../images/Linwah-afhaalmenu.pdf";


const settings = {
    infinite: true,
    dots: true,
    arrows: false,
    focusOnSelect: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: true,
    autoplay: true,
    centerMode: true,
    speed: 700,
    autoplaySpeed: 4000,
    adaptiveHeight: true,
    mobileFirst: true,
    centerPadding: '40px',
    responsive: [
        {
            breakpoint: 480,
            settings: {
                focusOnSelect: false,
                centerPadding: '0px',
                slidesToShow: 1
            }
        },
        {
            breakpoint: 992,
            settings: {
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 1
            }
        },
        {
            breakpoint: 1600,
            settings: {
                centerMode: true,
                centerPadding: '40px',
                slidesToShow: 2
            }
        }
    ]
};

const Layout = ({children}) => (
    <StaticQuery
        query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
        render={data => (

            <>
                <main>{children}</main>
                <Header/>
                <div className="hero" id="home">
                    <div className="hero__banner">
                        <img src={cherry} alt="Cherry Blossom"/>
                    </div>
                    <div className="mainContainer">
                        <div className="hero__sum">
                            <div className="hero__logo">
                                <img src={logo} alt="Cherry Blossom"/>
                            </div>
                            <h2>Chinese, Mongoolse,</h2>{"\n"}
                            <h2>Sichuan en Kantonese specialiteiten</h2>
                        </div>
                        <div className="hero__info">
                            <h2>Reserveer direct! <a href="tel:+31365360100" className="hero__tel">(036) 5360100</a>
                            </h2>{"\n"}
                            <h2>Iedere dag geopend van 12:00 tot 22:00 uur</h2>
                        </div>
                    </div>
                </div>
                <div className="menu" id="menu">
                    <div className="mainContainer">
                        <h1>Bij Lin Wah staat u als gast centraal</h1>{"\n"}
                        <h1>Bekijk hier onze zeer uitgebreide menukaart</h1>
                        <ul>
                        <li><p>U kunt bij ons al lunchen vanaf €6,95 tussen 12:00 en 16:00.</p></li>
                        <li><p>Ook verzorgen wij voor elke gelegenheid catering op maat en budget vanaf 10 personen.</p></li>
                        </ul>
                        <div className="menu__buttons">
                            <div className="menu__button-row">
                                <a className="lw-button--secondary lw-button--left" href={afhaalmenu} target="_blank">Afhaalmenu</a>
                                <a className="lw-button--secondary lw-button--right" href="https://bestellen.linwahalmere.nl/bestellen#cat_100426">Weekmenu</a>
                            </div>
                            <div className="menu__button-row">
                                <a className="lw-button--secondary lw-button--left" href="https://bestellen.linwahalmere.nl/bestellen#cat_100426">Speciaal menu</a>
                                <a className="lw-button--secondary lw-button--right" href="https://bestellen.linwahalmere.nl/bestellen#cat_100426">Aanbiedingsmenu</a>
                            </div>
                            <div className="menu__button-row">
                                <a className="lw-button" href="https://bestellen.linwahalmere.nl/home">Online bestellen</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="impressies" id="impressies">
                    <div className="mainContainer">
                        <h1>Impressies</h1>
                        <Slider {...settings}>
                            <div>
                                <img src={img1} alt="img1"/>
                            </div>
                            <div>
                                <img src={img2} alt="img2"/>
                            </div>
                            <div>
                                <img src={img3} alt="img3"/>
                            </div>
                            <div>
                                <img src={img4} alt="img4"/>
                            </div>
                            <div>
                                <img src={img5} alt="img5"/>
                            </div>
                            <div>
                                <img src={img6} alt="img6"/>
                            </div>
                        </Slider>
                    </div>
                </div>
                <div className="contact" id="contact">
                    <div className="mainContainer">
                        <h2>Reserveer direct! <a href="tel:+31365360100" className="hero__tel">(036) 5360100</a></h2>
                        <h2>Iedere dag geopend van 12:00 tot 22:00 uur</h2>
                        <div className="contact__address">
                            <h1>Rimsky-Korssakovweg 1 1323 LP, Almere</h1>
                        </div>
                        <div className="contact__map">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2436.216126629131!2d5.188286015331621!3d52.366500979785464!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c616d2f6b54bb7%3A0x24db2ba2d69e9859!2sRimsky-Korssakovweg+1%2C+1323+LP+Almere!5e0!3m2!1sen!2snl!4v1536328946511"
                                width="100%" height="300" frameBorder="0">
                                    
                                </iframe>
                        </div>
                    </div>
                </div>
            </>
        )}
    />
);

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout
